"use client";
import React from "react";
import dynamic from "next/dynamic";
import HeroSection from "./components/a_heroSection/heroSection";
import { useRouter } from "next/navigation";
import ScrollRestoration from "@/hooks/use-scroll-restoration";

const DynamicProject = dynamic(
  () => import("./components/b_projectSection/projects-section"),
  { ssr: true },
);

const DynamicPricing = dynamic(
  () => import("./components/c_pricingSection/pricingSection"),
  { ssr: true },
);
const DynamicProcess = dynamic(
  () => import("./components/d_processSection/ProcessSection"),
  { ssr: true },
);
// const DynamicServicesSection = dynamic(
//   () =>
//     import("./components/e_servicesSection/servicesSection").then(
//       (mod) => mod.ServicesSection,
//     ),
//   { ssr: true },
// );
const DynamicReviews = dynamic(
  () => import("./components/f_reviewsSection/ReviewsSection"),
  { ssr: true },
);
const DynamicTeam = dynamic(
  () => import("./components/g_teamSection/TeamSection"),
  {
    ssr: true,
  },
);
const DynamicForms = dynamic(
  () => import("./components/h_formsSection/FormsSection"),
  { ssr: true },
);
const DynamicFaqSection = dynamic(
  () => import("./components/i_faqSection/FaqSection"),
  { ssr: true },
);
const DynamicStartProject = dynamic(
  () => import("@/components/start-project"),
  {
    ssr: true,
  },
);

const HomePage = () => {
  // ne pas supprimer cette ligne
  const router = useRouter(); // ne pas supprimer cette ligne
  // ne pas supprimer cette ligne
  ScrollRestoration();

  return (
    <>
      <main className="flex min-h-screen max-w-[100vw] flex-col items-center justify-between">
        <HeroSection />
        <DynamicProject />
        <DynamicPricing />
        <DynamicStartProject />
        <DynamicProcess />
        {/* <DynamicServicesSection /> */}
        <DynamicReviews />
        <DynamicTeam />
        <DynamicForms />
        <DynamicStartProject />
        <DynamicFaqSection />
      </main>
    </>
  );
};

export default HomePage;
