import Image from "next/image";
import BeeDriver from "@/assets/img/clients/bee_driver.webp";
import Taxi from "@/assets/img/clients/taxi_beauchamp.webp";
import Jeff from "@/assets/img/clients/jeff_coaching_taekwondo.webp";
import Totem from "@/assets/img/clients/totem33.webp";
import Laetitia from "@/assets/img/clients/laetitia.webp";
import EcoHydroneCleaning from "@/assets/img/clients/eco-hydrone-cleaning.webp";
import WeblyInspiration from "@/assets/img/clients/logo_webly.svg";

const logos = [
  {
    key: "beedriver",
    logo: <Image src={BeeDriver} alt="BeeDriver" loading="lazy" />,
  },
  {
    key: "jeff",
    logo: (
      <Image src={Jeff} alt="Jeff" className="h-[60%] w-[60%]" loading="lazy" />
    ),
  },
  {
    key: "totem",
    logo: <Image src={Totem} alt="Totem" loading="lazy" />,
  },
  {
    key: "taxi",
    logo: <Image src={Taxi} alt="Taxi" loading="lazy" />,
  },
  {
    key: "laetitia",
    logo: (
      <Image
        src={Laetitia}
        className="h-[85%] w-[85%]"
        alt="Laetitia"
        loading="lazy"
      />
    ),
  },
  {
    key: "eco-hydrone-cleaning",
    logo: (
      <Image
        src={EcoHydroneCleaning}
        className="h-[90%] w-[90%]"
        alt="Eco Hydrone Cleaning"
        loading="lazy"
      />
    ),
  },

  {
    key: "white-logo",
    logo: (
      <Image
        src={WeblyInspiration}
        alt="White Logo"
        width={100}
        height={100}
        className="h-[65%] w-[65%]"
        loading="lazy"
      />
    ),
  },
];

export default logos;
