"use client";

import { Chip, Button } from "@nextui-org/react";
import Link from "next/link";
import { FlipWords } from "@/components/ui/flip-words";
import { TypewriterEffectSmooth } from "@/components/ui/typewriter-effect";
import useIntersectionObserver from "@/hooks/use-intersection-observer";
import ScrollingBanner from "./scrolling-banner";
import logos from "./logos";
import { useMediaQuery } from "usehooks-ts";

interface HeroSectionProps {
  className?: string;
}

const HeroSection: React.FC<HeroSectionProps> = () => {
  const isMobile = useMediaQuery("(max-width: 480px)");

  const handleStartProjectClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const startProjectSection = document.getElementById("start-project");
    if (startProjectSection) {
      const viewportHeight = window.innerHeight;
      const elementHeight = startProjectSection.offsetHeight;
      const scrollTo =
        startProjectSection.offsetTop - (viewportHeight - elementHeight) / 2;

      window.scrollTo({
        top: scrollTo,
        behavior: "smooth",
      });
    }
  };

  const words = [
    "Generation",
    "Level",
    "Generation",
    "Level",
    "Generation",
    "Level",
    "Generation",
  ];

  const sentence = [
    {
      text: "Votre",
    },
    {
      text: "partenaire",
    },
    {
      text: "digital",
    },
    {
      text: "de",
    },
    {
      text: "confiance.",
      className: "text-[#7B5FEB]",
    },
  ];

  const [ref, isIntersecting] = useIntersectionObserver({
    threshold: 0.1,
  });

  return (
    <>
      <div className="flex min-h-[calc(90vh_-_64px)] w-full flex-col justify-between overflow-x-hidden px-4 pt-24 ph:min-h-[calc(95vh_-_64px)] ph:px-0">
        <div className="mt-7 flex flex-col items-center">
          <Link href="#nos-realisations" className="mb-4">
            <Chip className="text-md bg-[#2c325b] text-white">
              Voir nos derniers projets
            </Chip>
          </Link>

          <div className="flex w-full flex-col items-center justify-center text-center">
            <h1
              ref={ref as React.RefObject<HTMLDivElement>}
              className="relative mx-auto whitespace-nowrap text-5xl leading-tight ph:text-6xl sm:text-7xl md:text-8xl lg:text-[140px]"
            >
              Next
              {isIntersecting && (
                <FlipWords words={words} className="text-start text-white" />
              )}
            </h1>
            <TypewriterEffectSmooth className="mt-2 ph:mt-6" words={sentence} />
          </div>

          <Button
            className="mt-14 bg-primary text-lg font-[500] text-white hover:bg-indigo-400 hover:text-white hover:!opacity-100"
            radius="sm"
            onClick={handleStartProjectClick}
          >
            Démarrer un projet
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M21.738 16.13a1 1 0 0 1-.19.61a1 1 0 0 1-.52.38l-1.71.57a3.57 3.57 0 0 0-1.4.86a3.5 3.5 0 0 0-.86 1.4l-.6 1.7a1 1 0 0 1-.36.51a1.08 1.08 0 0 1-.62.19a1 1 0 0 1-1-.71l-.57-1.71a3.5 3.5 0 0 0-.86-1.4a3.789 3.789 0 0 0-1.4-.87l-1.71-.56a1.11 1.11 0 0 1-.51-.37a1.08 1.08 0 0 1-.21-.62a1 1 0 0 1 .71-1l1.72-.57a3.54 3.54 0 0 0 2.28-2.28l.57-1.69a1 1 0 0 1 .95-.73c.215 0 .426.059.61.17c.182.125.322.303.4.51l.58 1.74a3.54 3.54 0 0 0 2.28 2.28l1.7.6a1 1 0 0 1 .51.38a1 1 0 0 1 .21.61m-9.999-6.36a1 1 0 0 1-.17.55a1 1 0 0 1-.47.35l-1.26.42c-.353.122-.673.32-.94.58a2.48 2.48 0 0 0-.58.94l-.43 1.24a.89.89 0 0 1-.35.47a1 1 0 0 1-.56.18a1 1 0 0 1-.57-.19a1 1 0 0 1-.34-.47l-.41-1.25a2.44 2.44 0 0 0-.58-.93a2.22 2.22 0 0 0-.93-.58l-1.25-.42a.93.93 0 0 1-.48-.35a1 1 0 0 1 .48-1.47l1.25-.41a2.49 2.49 0 0 0 1.53-1.53l.41-1.23a1 1 0 0 1 .32-.47a1 1 0 0 1 .55-.2a1 1 0 0 1 .57.16a1 1 0 0 1 .37.46l.42 1.28a2.49 2.49 0 0 0 1.53 1.53l1.25.43a.92.92 0 0 1 .46.35a.94.94 0 0 1 .18.56m5.789-5.36a1 1 0 0 1-.17.51a.82.82 0 0 1-.42.3l-.62.21a.84.84 0 0 0-.52.52l-.22.63a.929.929 0 0 1-.29.39a.82.82 0 0 1-.52.18a1.08 1.08 0 0 1-.49-.15a.92.92 0 0 1-.32-.44l-.21-.62a.719.719 0 0 0-.2-.32a.76.76 0 0 0-.32-.2l-.62-.2a1 1 0 0 1-.42-.31a.88.88 0 0 1-.16-.51a.94.94 0 0 1 .17-.51a.88.88 0 0 1 .42-.3l.61-.2a.91.91 0 0 0 .33-.2a.939.939 0 0 0 .2-.33l.21-.62c.06-.155.155-.292.28-.4a1 1 0 0 1 .49-.19a.94.94 0 0 1 .53.16a1 1 0 0 1 .32.41l.21.64a.942.942 0 0 0 .2.33a1 1 0 0 0 .32.2l.63.21a1 1 0 0 1 .41.3a.87.87 0 0 1 .17.51"
              ></path>
            </svg>
          </Button>
        </div>

        <ScrollingBanner shouldPauseOnHover gap={isMobile ? "25px" : "40px"}>
          {Array(2)
            .fill(logos)
            .flat()
            .map(({ key, logo }, index) => (
              <div
                key={`${key}-${index}`}
                className="flex w-[200px] items-center justify-center text-white"
              >
                {logo}
              </div>
            ))}
        </ScrollingBanner>
      </div>
    </>
  );
};

export default HeroSection;
